import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
// import Week from "../components/week"
// import Schedule from "../components/schedule"
import MiniLayout from "../../components/minimalLayout"


const Parasite = ({data}) => {

  return (
    <Layout>

      <MiniLayout>
        <h2>submit: parasite (project B)</h2>
        <p>In the next class, <Link to="../parasite">Project B</Link> is due.</p>
        
        <p>Because your extension needs to be installed to be experienced (as opposed to a website), a <b>high quality README.md page</b> is important.</p>
        <h4>Your README.md should include to parts:</h4>
        <p><b>1)</b> An <b>intro</b> that will convince someone to download the extension. Celebrate/Advertise its <b>concept and its features</b>, a download link (to a zip file (<a target="_blank" href="https://kaielwood.github.io/abc-student-repo/the-elwood-room/">example</a>)), and a well designed screenshot, ideally an animated GIF. Consider <a target="_black" href="https://docs.google.com/presentation/d/1KjjRXPjuEEtqOGh1Jeyr6beXEbGaL3WgpjeLPUYvL5w/edit?usp=sharing">this advice</a>.</p>
        <p><b>2)</b> The second part is a more <b>technical explanation</b> of your extension. Describe your process, technologies used (e.g. APIs), problems your faced, solutions you found and compromises you made. This part should be clearly seperated from the first part.</p>
        <p><a target="_blank" href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet">Markdown Cheatsheet</a></p>
        <br></br>
        <h4>Your Chrome Extension should have:</h4>
        <p>1) A well-considered title and decription.</p>
        <p>2) <a target="_blank" href="https://developer.chrome.com/extensions/manifest/icons">Custom icons</a> of three sizes (they will show up in <a target="_blank" href="chrome://extensions/">chrome://extensions/</a>).</p>

        <br></br>
        <p>Please do the following to submit your project:</p>
        <p>1. Push your project to your repository and link to it from your room page.</p>
        {/* <p>2. Be prepared to introduce your project next class. <b>We will have an outside guest critic who has never seen or heard of your project.</b> It the short presentation time, they won't get a whole first-hand experience; think very carefully about (1) what you show (e.g. which websites you visit in the Demo, which features of your extension you are showing) and (2) what you say alongside your demo. Incluce challenges you faced, solutions (if applicable), compromises and shortcomings of your project.</p> */}
        <p>2. Prepare you presentation and demo. We will have a lot of time for the presentations. Your presentation should include a (1) planned demo (think about which website you are using to show your extension, the order in which you explain its working), (2) an interactive part (let us try your extension on our computers), (3) explain the working of one chore mechanism in detail and (4) include challenges you faced, solutions (if applicable), compromises and shortcomings of your project.</p>
      </MiniLayout>
    </Layout>
  )
}



export default Parasite